import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { random, forEach } from 'lodash'

import { media } from '../../../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
} from '../../../styles/global'

import char01 from '../../../assets/characters/black/illustration-01.png'
import char02 from '../../../assets/characters/black/illustration-02.png'
import char03 from '../../../assets/characters/black/illustration-03.png'
import char04 from '../../../assets/characters/black/illustration-04.png'
import char05 from '../../../assets/characters/black/illustration-05.png'
import char06 from '../../../assets/characters/black/illustration-06.png'
import char07 from '../../../assets/characters/black/illustration-07.png'
import char08 from '../../../assets/characters/black/illustration-08.png'

// Todo: OnMouseLeave character image is changing

const BlockAboutList = (props) => {
    const { list, includeLink, theme } = props
    const [activeIndex, setActiveIndex] = useState(0)
    const [pause, pauseinterval] = useState(false)

    if (!list) return <></>

    // Cycle through characters

    useEffect(() => {
        if (pause) return

        const interval = setInterval(() => {
            setActiveIndex(random(0, list.length))
        }, 1400)

        return () => clearInterval(interval)
    })

    // Setup characters

    const randomCharacterArray = (value) => {
        let num = Math.floor(Math.random() * theCharacters.length)
        return theCharacters[num]
    }

    let image = list.map(randomCharacterArray)

    // Handle hoverstate

    const handleHover = (boolean, index) => {
        pauseinterval(boolean)

        if (index) {
            setActiveIndex(index)
        }
    }

    // List

    const renderItems = () => {
        return list.map((item, i) => {
            return (
                <>
                    {includeLink && item.link ? (
                        <Wrapper key={i}>
                            <Item
                                as={'a'}
                                href={item.link}
                                onMouseEnter={() =>
                                    handleHover(true, i.toString())
                                }
                            >
                                <Icon
                                    animate={
                                        activeIndex == i ? 'hidden' : 'show'
                                    }
                                    variants={animatedItem}
                                />

                                <Character
                                    src={image[i]}
                                    animate={
                                        activeIndex == i ? 'show' : 'hidden'
                                    }
                                    variants={animatedItem}
                                />
                                <Label>{item.title}</Label>
                            </Item>
                        </Wrapper>
                    ) : (
                        <Wrapper key={i}>
                            <Item
                                onMouseEnter={() =>
                                    handleHover(true, i.toString())
                                }
                            >
                                <Icon
                                    animate={
                                        activeIndex == i ? 'hidden' : 'show'
                                    }
                                    variants={animatedItem}
                                />
                                <Character
                                    src={image[i]}
                                    animate={
                                        activeIndex == i ? 'show' : 'hidden'
                                    }
                                    variants={animatedItem}
                                />
                                <Label>{item.title}</Label>
                            </Item>
                        </Wrapper>
                    )}
                </>
            )
        })
    }

    return <List onMouseLeave={() => handleHover(false)}>{renderItems()}</List>
}

// Shared

const Wrapper = styled.div``
const List = styled.div``
const Item = styled(motion.div)``
const Icon = styled(motion.div)``
const Character = styled(motion.img)``
const Label = styled.p``

// List

BlockAboutList.wrapper = css`
    flex-basis: 40%;

    ${List} {
        display: flex;
        flex-direction: column;
        position: relative;

        ${(props) => {
            if (props.theme == 'normal') return normal
            if (props.theme == 'red') return red
            if (props.theme == 'black') return black
        }}
    }
`

const normal = css`
	${Item} {
		display: flex;
		padding-bottom: 4px;
		${type.heading2}
		color: ${colours.black};
		cursor: pointer;
		position: relative;
		transition: color 0.8s ease;

		${media.phone`
			padding-bottom: 8px;
		`}

		${Icon} {
			position: absolute;
			width: 32px;
			height: 32px;
			background-image: url(${require('../../../assets/icons/red-circle.svg')});
			${bgIcon}
			transition: background-image 1s ease;
		}

		${Character} {
			position: absolute;
			top: -8px;
			left: -20px;
			max-width: 70px;
		}

		${Label} {
			padding-left: 50px;
			margin: 0;
			font-size: 40px;

			${media.phone`
				font-size: 30px;
			`}

			&:hover {
				color: ${colours.red};
			}
		}
	}
`

const red = css`
	${Item} {
		display: flex;
        padding-bottom: 4px;
        ${type.heading2}
		color: ${colours.white};
		cursor: pointer;
		position: relative;
		transition: color 0.8s ease;

		${media.phone`
			padding-bottom: 8px;
		`}

		${Icon} {
			position: absolute;
			width: 32px;
			height: 32px;
			background-image: url(${require('../../../assets/icons/black-circle.svg')});
			${bgIcon}
			transition: background-image 1s ease;
		}

		${Character} {
			position: absolute;
			top: -8px;
			left: -20px;
			max-width: 70px;
		}

		${Label} {
			padding-left: 50px;
			margin: 0;
			font-size: 40px;

			${media.phone`
				font-size: 30px;
			`}

			&:hover {
                color: ${colours.white};
            }
		}
    }
`

const black = css`
	${Item} {
		display: flex;
        padding-bottom: 4px;
        ${type.heading2}
		color: ${colours.white};
		cursor: pointer;
		position: relative;
		transition: color 0.8s ease;

		${media.phone`
			padding-bottom: 8px;
		`}

		${Icon} {
			position: absolute;
			width: 32px;
			height: 32px;
			background-image: url(${require('../../../assets/icons/red-circle.svg')});
			${bgIcon}
			transition: background-image 1s ease;
		}

		${Character} {
			position: absolute;
			top: -8px;
			left: -20px;
			max-width: 70px;
		}

		${Label} {
			padding-left: 50px;
			margin: 0;
			font-size: 40px;

			${media.phone`
				font-size: 30px;
			`}

			&:hover {
                color: ${colours.red};
            }
		}
    }
`

// Animations

const theCharacters = [
    char01,
    char02,
    char03,
    char04,
    char05,
    char06,
    char07,
    char08,
]

const animatedContainer = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5,
            delayChildren: 1,
            yoyo: Infinity,
        },
    },
}

const animatedItem = {
    hidden: {
        scaleX: 0,
        scaleY: 0,
    },
    show: {
        scaleX: 1,
        scaleY: 1,
        transition: {
            ease: 'anticipate',
        },
    },
}

export default BlockAboutList
