import { css } from 'styled-components'

const zIndex = {
    header: css`
        z-index: 400;
    `,
    menu: css`
        z-index: 500;
    `,
    screensaver: css`
        z-index: 600;
    `,
}

export default zIndex
