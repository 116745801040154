import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'
import {
    motion,
    useTransform,
    useSpring,
    useViewportScroll,
    AnimatePresence,
} from 'framer-motion'

import { container, padding, colours, bgIcon } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'

import HeroLogo from '../../assets/icons/logo.svg'
import HeroLogoRed from '../../assets/icons/logo-black.svg'

import char01White from '../../assets/images/character-white-01.png'
import char02White from '../../assets/images/character-white-02.png'
import char03White from '../../assets/images/character-white-03.png'
import char04White from '../../assets/images/character-white-04.png'

import char01Black from '../../assets/characters/black/illustration-01.png'
import char02Black from '../../assets/characters/black/illustration-02.png'
import char03Black from '../../assets/characters/black/illustration-03.png'

const Logo = (props) => {
    const { theme, useBlack, stopResize } = props
    const [character, updateCharacter] = useState(0)
    const [active, setActive] = useState(false)
    const [scrollComplete, setScrollComplete] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const { width } = useWindowSize()
    const { scrollYProgress } = useViewportScroll()
    const detectMobile = useBreakpoint('phone')

    // Setup scroll

    const yRange = useTransform(scrollYProgress, [0.9, 1], [0, width - 200])
    const scale = useSpring(yRange, { stiffness: 100, damping: 30 })

    useEffect(() => {
        if (!stopResize) {
            scrollYProgress.onChange((value) => setScrollComplete(value >= 0.8))
        }
    }, [scrollYProgress])

    // Check if Mobile

    useMount(() => {
        setIsMobile(detectMobile)
    })

    useEffect(() => {
        setIsMobile(detectMobile)
    }, [detectMobile])

    // Handle Hover

    const handleHover = (boolean) => {
        if (!scrollComplete) {
            if (boolean == true) {
                setActive(boolean)
                updateCharacter(character + 1)
            }

            setActive(boolean)
        }
    }

    // Characters

    const renderCharacters = (props) => {
        let theCharacters = whiteCharacters

        if (useBlack) {
            theCharacters = blackCharacters
        }

        const image = theCharacters[character % 3]

        const items = theCharacters.map((theCharacters, i) => {
            return (
                <CharItem
                    src={image}
                    key={i}
                    animate={active ? 'animate' : 'exit'}
                    variants={charVariants}
                />
            )
        })

        return <AnimatePresence>{items}</AnimatePresence>
    }

    return (
        <>
            <Container as={Link} to="/" scrollComplete={scrollComplete}>
                <Wrapper theme={theme}>
                    <Typemark
                        src={theme == 'red' ? HeroLogoRed : HeroLogo}
                        animate={active ? 'animate' : 'initial'}
                        variants={logoVariants}
                        onMouseEnter={() => handleHover(true)}
                        onMouseLeave={() => handleHover(false)}
                        style={{ width: !stopResize ? scale : 0 }}
                    />
                </Wrapper>
            </Container>
            {renderCharacters()}
        </>
    )
}

// Shared

const Typemark = styled(motion.img)``

const Container = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 20px 20px;
    box-sizing: border-box;
    z-index: 400;

    ${(props) => {
        if (props.scrollComplete)
            return css`
                mix-blend-mode: darken;
                pointer-events: none;
            `
    }}
`

const Wrapper = styled.div`
    position: relative;

    ${(props) => {
        if (props.theme == 'normal') return normal
        if (props.theme == 'red') return red
        if (props.theme == 'black') return normal
    }}
`

// Logo

const normal = css`
    ${Typemark} {
        position: relative;
        min-width: 244px;
        height: 100%;
        transform-origin: bottom right;

        ${media.phone`
			min-width: 130px;
		`}
    }
`

const red = css`
    ${Typemark} {
        min-width: 244px;
        height: 100%;
        transform-origin: bottom right;

        ${media.phone`
			min-width: 130px;
		`}
    }
`

// Animation
const logoVariants = {
    initial: {
        scale: 1,
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 100,
        },
    },
    animate: {
        scale: 1.4,
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 100,
        },
    },
}

// Characters

const whiteCharacters = [char01White, char02White, char03White, char04White]
const blackCharacters = [char01Black, char02Black, char03Black]

const charVariants = {
    initial: {
        y: '100%',
        x: '-50%',
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 100,
        },
    },
    animate: {
        y: '40%',
        x: '-50%',
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 100,
        },
    },
    exit: {
        y: '100%',
        x: '-50%',
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 100,
        },
    },
}

const CharItem = styled(motion.img)`
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 60vw;
    pointer-events: none;
    z-index: 40;
    transform: translateX(-50%) translateY(100%);
`

Logo.defaultProps = {
    theme: 'normal',
}

export default Logo
