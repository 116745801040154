import React, { useState, useEffect, createContext } from 'react'

export const headerContext = createContext()

const HeaderProvider = (props) => {
    const { Provider } = headerContext
    const [active, setActive] = useState(false)

    return (
        <Provider
            value={{
                active,
                setActive,
            }}
        >
            {props.children}
        </Provider>
    )
}

export default HeaderProvider
