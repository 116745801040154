import React, { useState, useEffect } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { useMount, useMedia } from 'react-use'
import Marquee, { Motion, randomIntFromInterval } from 'react-marquee-slider'
import times from 'lodash/times'

import { isClient } from '../../utils'
import { media } from '../../styles/utils'
import { container, padding, colours, hoverState } from '../../styles/global'

const Ticker = (props) => {
    const { content, velocity, direction, hoverColor } = props

    return (
        <Wrapper hoverColor={hoverColor}>
            <Marquee velocity={velocity} direction={direction}>
                {times(3, Number).map((id) => (
                    <Content
                        key={id}
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    />
                ))}
            </Marquee>
        </Wrapper>
    )
}

const Content = styled.div``

const Wrapper = styled.div`
    z-index: 1;

    ${Content} {
        font-size: 120px;
        line-height: 1.1;
        margin: 0;
        margin-right: 300px;

        ${media.tablet`
			font-size: 80px;
			margin-right: 100px;
		`}

        ${media.phone`
			font-size: 52px;
			margin-right: 100px;
		`}

        &:hover {
            color: ${(props) => props.hoverColor || 'white'};
            cursor: pointer;
        }
    }
`

export default Ticker
