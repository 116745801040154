import React from 'react'
import { Provider } from 'react-redux'
import Transition from './src/transition'
import { GlobalStyles } from './src/styles/global'
import { PortalProvider } from 'react-portal-hook'

import HeaderProvider from './src/components/Header/HeaderProvider'

export default ({ element, props }) => {
    return (
        <>
            <GlobalStyles />
            <PortalProvider>
                <HeaderProvider>
                    <Transition {...props}>{element}</Transition>
                </HeaderProvider>
            </PortalProvider>
        </>
    )
}
