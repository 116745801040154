import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { useLocation, useWindowScroll } from 'react-use'
import styled, { css, createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'

import { media } from '../../styles/utils'
import { container, padding, colours, zindex } from '../../styles/global'
import { isClient } from '../../styles/utils'
import { useMount } from 'react-use'

const Splash = (props) => {
    const [animate, setAnimate] = useState(false)

    // Set splash visibility / Local storage

    let timer

    useMount(() => {
        if (isClient()) {
            let splash = document.getElementById('splash')

            if (sessionStorage.introSeen == 'true') {
                if (splash) {
                    splash.classList.add('transition')
                    splash.classList.remove('active')
                }
            } else {
                if (splash) {
                    splash.classList.add('active')
                }

                setAnimate(true)
                document.body.style.overflow = 'hidden'
            }
        }
    })

    const seenSplash = () => {
        if (sessionStorage.introSeen !== 'true') {
            clearInterval(timer)

            let splash = document.getElementById('splash')

            if (splash) {
                splash.classList.add('transition')
                splash.classList.remove('active')
            }

            setTimeout(() => {
                sessionStorage.introSeen = true
                document.body.style.overflow = 'auto'
            }, 3500)
        }
    }

    // Render phases / words

    const renderWords = (props) => {
        if (!props) return

        const items = props.map((item, i) => {
            return (
                <Word key={i} variants={animatedItem}>
                    {item}
                </Word>
            )
        })

        return <Sentence>{items}</Sentence>
    }

    return (
        <>
            <SplashStyles />
            <Wrapper
                id={'splash'}
                animate={animate ? 'show' : 'hidden'}
                variants={animatedContainer}
                onAnimationComplete={seenSplash}
            >
                <WordWrap
                    initial="hidden"
                    animate="show"
                    variants={animatedContainer}
                >
                    {renderWords(['Hero', 'Hero', 'Hero'])}
                    {renderWords(['Purveyors', 'of', 'Talent'])}
                    {renderWords(['For', 'Modern', 'Production'])}
                    {renderWords([
                        'Los Angeles',
                        'San Francisco',
                        'Austin',
                        'Las Vegas',
                    ])}
                    {renderWords(['heromgmt.tv', '@heromgmt', 'heromgmt.tv'])}
                    {renderWords([
                        'Portland',
                        'Dallas',
                        'Boulder',
                        'Denver',
                        'Seattle',
                    ])}
                    {renderWords(['Purveyors', 'of', 'Talent'])}
                    {renderWords(['For', 'Modern', 'Production'])}
                    {renderWords(['heromgmt.tv', 'heromgmt.tv'])}
                </WordWrap>
            </Wrapper>
        </>
    )
}

// Shared
const WordWrap = styled(motion.div)``
const Sentence = styled(motion.div)``
const Word = styled(motion.h2)``

const SplashStyles = createGlobalStyle`
    #splash {
	    opacity: 0;
        pointer-events: none;
		clip-path: inset(0 0 100% 0);
		
        &.active {
            opacity: 1;
            pointer-events: all;
			clip-path: inset(0 0 0 0);
		}

		&.transition {
			transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
			transition-delay: 3s;
		}
    }
`

// Wrapper

const Wrapper = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: ${colours.red};
    z-index: 800;
    opacity: 0;
    pointer-events: none;
    padding: 30px;

    ${media.phone`
		padding: 12px;
		height: 100%;
		min-height: fill-available;
	`}

    ${WordWrap} {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${media.phone`
			justify-content: space-around;
		`}

        ${Sentence} {
            overflow: hidden;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 8px 0;

            &:nth-child(odd) {
                justify-content: space-around;
            }

            /* Hide the last 5 lines on phone */
            &:nth-last-child(-n + 6) {
                ${media.phone`
					display: none;
				`}
            }

            ${Word} {
                text-transform: uppercase;
                font-size: 24px;

                ${media.phone`
					font-size: 18px;
				`}
            }
        }
    }
`

const animatedContainer = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.05,
        },
    },
}

const animatedItem = {
    hidden: { y: '200%' },
    show: {
        y: '0%',
        transition: {
            type: 'spring',
            //damping: 50,
            stiffness: 80,
            mass: 1,
        },
    },
}

export default Splash
