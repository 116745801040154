import React, { useState, useRef } from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'
import DynamicForm, { useForm } from 'react-dynamic-form'
import { useSetState } from 'react-use'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Input } from '../'

import { media } from '../../styles/utils'
import { container, padding, colours, type } from '../../styles/global'
import { newsletterForm } from './data'
import iconArrow from '../../assets/icons/arrow-black.svg'

// Todo: hook up to mailchimp and add thankyou/error styling

const Newsletter = (props) => {
    const { heading, text, formStyles } = props
    const [form, { formSubmit, setError }] = useForm()
    const [formComplete, setFormComplete] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        let data = {
            email: fields['email'].value,
        }

        addToMailchimp(data.email, {}).then((mc_resp) => {
            if (mc_resp.result == 'success') {
                setFormComplete(true)
                setError(false)
                console.log('form complete:', formComplete)
            }

            if (mc_resp.result == 'error') {
                setError(mc_resp.msg)
                console.log('form error:', mc_resp.msg)
            }
        })

        resetForm()
    }

    // Form
    const renderForm = () => {
        return (
            <Signup>
                {!formComplete && (
                    <FormWrapper>
                        <DynamicForm
                            form={form}
                            data={newsletterForm}
                            renderInput={<Input />}
                            moveToInvalidField={false}
                            styles={formStyles}
                            renderSubmit={false}
                            onSubmit={handleSubmit}
                        />

                        <Button theme={'outline-black'} onClick={formSubmit}>
                            <img src={iconArrow} />
                        </Button>
                    </FormWrapper>
                )}

                {form.error && (
                    <Error
                        dangerouslySetInnerHTML={{
                            __html: form.error,
                        }}
                    />
                )}

                {formComplete && (
                    <ThankYou
                        dangerouslySetInnerHTML={{
                            __html: `
							Thanks for joining! 
						`,
                        }}
                    />
                )}
            </Signup>
        )
    }

    return (
        <Wrapper>
            <Container>
                <Heading>{heading}</Heading>
                {renderForm()}
            </Container>
        </Wrapper>
    )
}

// Shared

const Subheading = styled.h3``
const Description = styled.div``
const Button = styled.div``

// Layout

const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 70px;

    ${media.phone`
		width: 100%;
		padding-bottom: 40px;
	`}
`

const Container = styled.div`
    width: 100%;
`

const Heading = styled.h2`
    padding-bottom: 32px;
    color: ${colours.red};

    ${media.phone`
		padding-bottom: 20px;
	`}
`

// Form

const Signup = styled.div`
    display: flex;
    flex-direction: column;
`

const FormWrapper = styled.div`
    display: flex;
    width: 100%;

    ${Button} {
        border-bottom: 1px solid black;
        cursor: pointer;

        img {
            width: 30px;
        }
    }
`

const formStyles = css`
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    width: 100%;

    .dynamic-fields {
        width: 100%;
        display: flex;
        padding-right: 105px;
        border-bottom: 1px solid black;

        ${media.phone`
			flex-direction: column;
			align-items: center;
			padding-right: 0;
		`}
    }

    .dynamic-field {
        margin-bottom: 16px;
        width: 100%;
        height: 42px;

        input {
            width: 100%;
            background: white;
            padding: 0;
            letter-spacing: 0;

            &::placeholder {
                letter-spacing: 0;
            }
        }

        &.email {
            margin-right: 5px;
        }
    }

    .error {
        margin-top: 32px;

        &,
        p,
        a {
            font-size: 16px;
            color: ${colours.black} !important;
        }
    }
`

const ThankYou = styled.div`
    font-size: 24px;
    padding-top: 12px;
`

const Error = styled.div`
    font-size: 16px;
    padding-top: 12px;

    a {
        font-size: 16px;
        color: ${colours.red};
    }
`

export default Newsletter
