import React, { useState, useEffect, useContext } from 'react'
import { navigate, Link } from 'gatsby'
import { useLocation, useWindowScroll, useMount } from 'react-use'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { media } from '../../styles/utils'
import {
    container,
    padding,
    colours,
    zindex,
    bgIcon,
} from '../../styles/global'
import { headerContext } from './HeaderProvider'

import { Menu } from '../'

import cameraIcon from '../../assets/icons/camera.svg'

const Header = (props) => {
    const { hideLogo, menuMessage, theme } = props
    const { x, y } = useWindowScroll()
    const { active, setActive } = useContext(headerContext)

    return (
        <>
            <Menu menuMessage={menuMessage} />

            <Container>
                <Hamburger theme={theme}>
                    {!props.hideLogo && (
                        <Icon
                            whileHover={{ scale: 1.1 }}
                            transition={{
                                type: 'spring',
                                damping: 10,
                                stiffness: 100,
                            }}
                            onClick={() => {
                                active ? setActive(false) : setActive(true)
                            }}
                        />
                    )}
                </Hamburger>
            </Container>
        </>
    )
}

// Shared
const Icon = styled(motion.div)``

const Container = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    ${zindex.header}
`

const Hamburger = styled.div`
    padding: 24px 60px 0;

    ${media.tablet`
		padding: 24px 18px;
	`}

    ${(props) => {
        if (props.theme == 'normal') return normal
        if (props.theme == 'red') return red
        if (props.theme == 'black') return normal
    }}
`

const normal = css`
    ${Icon} {
        width: 70px;
        height: 50px;
        cursor: pointer;
        background-image: url(${require('../../assets/icons/camera.svg')});
        ${bgIcon}

        ${media.tablet`
			width: 52px;
			height: 32px;
		`}
    }
`

const red = css`
    ${Icon} {
        width: 70px;
        height: 50px;
        cursor: pointer;
        background-image: url(${require('../../assets/icons/camera-black.svg')});
        ${bgIcon}

        ${media.tablet`
			width: 52px;
			height: 32px;
		`}
    }
`

Header.defaultProps = {
    theme: 'normal',
}

export default Header
