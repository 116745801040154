import React, { useState, useEffect, useContext, useRef } from 'react'
import { navigate, Link } from 'gatsby'
import { useWindowScroll } from 'react-use'
import styled, { css } from 'styled-components'
import Typed from 'react-typed'
import { motion, AnimatePresence } from 'framer-motion'

import { media } from '../../styles/utils'
import { padding, colours, type, zindex } from '../../styles/global'
import { headerContext } from '../Header/HeaderProvider'

import char01 from '../../assets/characters/white/illustration-01.png'
import char02 from '../../assets/characters/white/illustration-02.png'
import char03 from '../../assets/characters/white/illustration-03.png'
import char04 from '../../assets/characters/white/illustration-04.png'

const Menu = (props) => {
    const { menuMessage } = props
    const { active, setActive } = useContext(headerContext)
    const [character, updateCharacter] = useState(0)
    const [pause, pauseinterval] = useState(false)

    useEffect(() => {
        if (pause) return
        // Cycle through characters

        const interval = setInterval(() => {
            updateCharacter(character + 1)
        }, 3000)

        return () => clearInterval(interval)
    })

    useEffect(() => {
        //active ? pauseinterval(true) : pauseinterval(false)
        //if (active) {
        //    pauseinterval(false)
        //} else {
        //    pauseinterval(true)
        //}

        console.log(pause)
    }, [active])

    // Typewriter

    const renderTyper = () => {
        return (
            <Typed
                strings={[
                    menuMessage ||
                        'We expose, We partner, we give life to creative storytelling.',
                ]}
                typeSpeed={50}
                backSpeed={30}
                fadeOut={true}
                start={active ? true : false}
            />
        )
    }

    // Nav Items

    const renderNavItems = (items) => {
        return navItems.map((item, i) => {
            return (
                <NavItem
                    key={i}
                    as={item.link && Link}
                    to={item.link}
                    onClick={() => setActive(false)}
                >
                    {item.title}
                </NavItem>
            )
        })
    }

    // Characters

    const renderCharactersLeft = () => {
        const image = leftCharacters[character % 3]

        return (
            <CharLeft>
                <AnimatePresence exitBeforeEnter>
                    <CharItem
                        src={image}
                        key={image}
                        animate={active ? 'initial' : 'animate'}
                        {...leftCharacterVariants}
                        style={{
                            opacity: active ? 1 : 0,
                        }}
                    />
                </AnimatePresence>
            </CharLeft>
        )
    }

    const renderCharactersRight = () => {
        const image = rightCharacters[character % 3]

        return (
            <CharRight>
                <AnimatePresence exitBeforeEnter>
                    <CharItem
                        src={image}
                        key={image}
                        animate={active ? 'initial' : 'animate'}
                        {...rightCharacterVariants}
                        style={{
                            opacity: active ? 1 : 0,
                        }}
                    />
                </AnimatePresence>
            </CharRight>
        )
    }

    return (
        <Container
            active={active}
            animate={!active ? 'initial' : 'animate'}
            variants={menuVariants}
        >
            <Close onClick={() => setActive(false)}>Close</Close>
            <Text>{renderTyper()}</Text>
            <Nav>{renderNavItems()}</Nav>

            {renderCharactersLeft()}
            {renderCharactersRight()}
        </Container>
    )
}

// Shared

// Container

const Container = styled(motion.div)`
    ${padding}
    padding-top: 40px;
    padding-bottom: 20px;
    width: 100%;
    background: ${colours.red};
    min-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    overflow: hidden;
    ${zindex.menu}
    transform: translateY(-100%);
    transition: box-shadow 1s ease;

    ${media.tablet`
		justify-content: center;
	`}

	${media.phone`
		min-height: fill-available;
		height: 100%;
	`}

    ${(props) => {
        if (props.active)
            return css`
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
            `
    }};
`

// Close

const Close = styled.div`
    position: relative;
    font-size: 20px;
    color: black;
    cursor: pointer;
    transition: color 1s ease;

    ${media.tablet`
		position: absolute;
		top: 0;
		left: 0;
		padding: 30px;
		box-sizing: border-box;
	`}

    &:hover {
        color: ${colours.white};
    }
`

// Text

const Text = styled.h2`
    ${type.heading1}
    text-align: center;
    padding: 0 40px;
    z-index: 40;
    min-height: 200px;
    max-width: 1000px;
    margin: 0 auto;

    ${media.tablet`
			display: none;
		`}
`

// Characters

const CharLeft = styled.div`
    position: absolute;
    left: -50px;
    bottom: -100px;
    pointer-events: none;

    ${media.tablet`
		left: -80px;
		bottom: -70px;
	`}
`

const CharRight = styled.div`
    position: absolute;
    right: -50px;
    top: -100px;
    pointer-events: none;

    ${media.tablet`
		right: -60px;
		top: -60px;
	`}
`

const CharItem = styled(motion.img)`
    width: 500px;
    transition: opacity 0.4s ease;

    ${media.tablet`
		width: 300px;
	`}
`

// Nav Items

const Nav = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    z-index: 40;

    ${media.tablet`
		flex-direction: column;
	`}
`

const NavItem = styled.div`
    text-align: center;
    font-size: 20px;
    color: ${colours.black};
    text-transform: uppercase;

    ${media.tablet`
		text-transform: capitalize;
		font-size: 40px;
	`}

    &[aria-current="page"] {
        color: ${colours.white};
    }

    &:hover {
        color: ${colours.white};
        transition: 0.4s ease;
    }
`

// Nav data

const navItems = [
    {
        title: 'Home',
        link: '/',
    },
    {
        title: 'About',
        link: '/about',
    },
    {
        title: 'Roster',
        link: '/roster',
    },
    {
        title: 'Heroes',
        link: '/heroes',
    },
    {
        title: 'Contact',
        link: '/contact',
    },
]

// Animation

const menuVariants = {
    initial: {
        y: '-99%',
        transition: {
            duration: 1,
            type: 'spring',
            stiffness: 100,
            damping: 50,
            mass: 1,
        },
    },
    animate: {
        y: '0%',
        transition: {
            duration: 1,
            type: 'spring',
            stiffness: 100,
            damping: 50,
            mass: 1,
        },
    },
}

const leftCharacters = [char01, char04, char01]
const rightCharacters = [char04, char01, char04]

const leftCharacterVariants = {
    initial: {
        y: '100%',
    },
    animate: {
        y: '0%',
        transition: {
            ease: 'anticipate',
            type: 'tween',
            duration: 1,
        },
    },
    exit: {
        y: '100%',
        transition: {
            delay: 1,
            ease: [0.4, 0, 0.2, 1],
            duration: 1,
        },
    },
}

const rightCharacterVariants = {
    initial: {
        y: '-100%',
    },
    animate: {
        y: '0%',
        transition: {
            ease: 'anticipate',
            type: 'tween',
            duration: 1,
        },
    },
    exit: {
        y: '-100%',
        transition: {
            delay: 1,
            ease: [0.4, 0, 0.2, 1],
            duration: 1,
        },
    },
}

export default Menu
