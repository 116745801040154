import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useWindowSize } from 'react-use'
import times from 'lodash/times'

import { media } from '../../styles/utils'
import {
    container,
    padding,
    colours,
    zindex,
    bgIcon,
} from '../../styles/global'

const Screensaver = (props) => {
    const { width, height } = useWindowSize()

    return (
        <Wrapper initial="hidden" animate="show" variants={animatedContainer}>
            {times(40, Number).map((id) => (
                <Character
                    variants={animatedItem}
                    key={id}
                    style={{
                        top: (Math.random() * height - 100).toFixed() + 'px',
                        left: (Math.random() * width - 100).toFixed() + 'px',
                    }}
                />
            ))}
        </Wrapper>
    )
}

// Shared
const Character = styled(motion.div)``

// Layout

const Wrapper = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    ${zindex.screensaver}
    pointer-events: none;

    ${Character} {
        position: absolute;
        width: 280px;
        height: 322px;
        background-image: url(${require('../../assets/images/character-screensaver.png')});
        ${bgIcon}

        ${media.phone`
			width: 150px;
			height: 195px;
		`}
    }
`
// Animation

const animatedContainer = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 1,
        },
    },
}

const animatedItem = {
    hidden: { scale: 0 },
    show: {
        scale: 1,
        transition: {
            ease: 'anticipate',
        },
    },
}

export default Screensaver
