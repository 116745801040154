import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useMount, useLocation } from 'react-use'
import { useBreakpoint } from './styles/utils'
import { useIdle } from 'react-use'
import { find } from 'lodash'

import { Splash, Screensaver } from './components'

const Transition = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false)
    const [isProject, setIsProject] = useState(false)
    const detectMobile = useBreakpoint('phone')
    const location = useLocation()

    const detectProjectPage = location?.pathname
        ?.toString?.()
        .includes('project')

    useMount(() => {
        setIsMobile(detectMobile)
        setIsProject(detectProjectPage)
    })

    useEffect(() => {
        setIsMobile(detectMobile)
    }, [detectMobile])

    useEffect(() => {
        setIsProject(detectProjectPage)
    }, [detectProjectPage])

    // Detect if user is idle
    const isIdle = useIdle(40000)

    return (
        <>
            <Splash />

            {isIdle && !isProject && <Screensaver />}

            <AnimatePresence>
                <Wrapper>{children}</Wrapper>
            </AnimatePresence>
        </>
    )
}

const Wrapper = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
`

const duration = 0.8
const variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: duration,
            delay: duration,
            when: 'beforeChildren',
        },
    },
    exit: {
        opacity: 0,
        transition: { duration: duration },
    },
}

export default Transition
