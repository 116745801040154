import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { useLocation, useWindowScroll } from 'react-use'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence, useCycle } from 'framer-motion'
import { random } from 'lodash'

import { Newsletter } from '../../components'
import { media } from '../../styles/utils'
import { getGlobalData, scrollToTop } from '../../utils'
import { container, padding, colours, zindex } from '../../styles/global'

import iconInstgram from '../../assets/icons/instagram.svg'
import iconArrowUp from '../../assets/icons/arrow-up.svg'

import redCircle from '../../assets/icons/red-circle.svg'
import char01 from '../../assets/characters/black/illustration-01.png'
import char02 from '../../assets/characters/black/illustration-02.png'
import char03 from '../../assets/characters/black/illustration-03.png'
import char04 from '../../assets/characters/black/illustration-04.png'

// Todo: Need to avoid dupes when setting a random number (active index state)

const Footer = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [pause, pauseinterval] = useState(false)
    const [background, setBackground] = useState('transparent')

    const { data } = getGlobalData('footer')
    const dateStamp = new Date()

    useEffect(() => {
        if (pause) return

        // Cycle through characters
        const interval = setInterval(() => {
            setActiveIndex(random(0, data.roster.length))
        }, 1400)

        return () => clearInterval(interval)
    })

    // Roster

    const handleHover = (boolean, index) => {
        pauseinterval(boolean)

        if (index) {
            setActiveIndex(index)
        }
    }

    const renderRoster = (props) => {
        if (!data.roster) return

        // Setup characters

        const randomCharacterArray = (value) => {
            let num = Math.floor(Math.random() * theCharacters.length)
            return theCharacters[num]
        }

        let image = data.roster.map(randomCharacterArray)

        return (
            <List>
                {data.roster.map((item, i) => (
                    <Item
                        as={'a'}
                        key={i}
                        href={item.link}
                        target="_blank"
                        onMouseEnter={() => handleHover(true, i.toString())}
                        onMouseLeave={() => handleHover(false)}
                    >
                        <Wrapper>
                            <Icon
                                src={redCircle}
                                animate={activeIndex == i ? 'hidden' : 'show'}
                                variants={animatedItem}
                            />
                            <Character
                                src={image[i]}
                                animate={activeIndex == i ? 'show' : 'hidden'}
                                variants={animatedItem}
                            />
                        </Wrapper>
                        <Label>{item.title}</Label>
                    </Item>
                ))}
            </List>
        )
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <Roster>
                        <Heading>Roster</Heading>
                        {renderRoster()}
                    </Roster>

                    <Info>
                        <Contact>
                            <Heading>Contact</Heading>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: data.contact,
                                }}
                            />
                        </Contact>
                        <Follow>
                            <Heading>Follow</Heading>
                            <Instagram
                                as={'a'}
                                href="https://www.instagram.com/heromgmt/"
                                target="_blank"
                            >
                                <Icon src={iconInstgram} />
                            </Instagram>
                            <Newsletter formStyles={formStyles} />
                        </Follow>
                    </Info>
                </Wrapper>

                <Meta>
                    <Text
                        as={'a'}
                        href="https://www.morebymore.com"
                        target="_blank"
                    >
                        Made by More Studio
                    </Text>
                    <Copyright>
                        All rights reserved, copyright Hero{' '}
                        {dateStamp.getFullYear()}
                    </Copyright>
                    <Arrow onClick={() => scrollToTop()} src={iconArrowUp} />
                </Meta>
            </Container>
        </>
    )
}

// Shared

const Wrapper = styled.div``
const Heading = styled.h2``
const List = styled(motion.div)``
const Item = styled(motion.a)``
const Text = styled.div``
const Copyright = styled.p``
const Instagram = styled.div``
const Icon = styled(motion.img)``
const Character = styled(motion.img)``
const Label = styled.p``
const Arrow = styled.img``

// Layout

const Container = styled.div`
    ${padding}
    padding-top: 100px;
    padding-bottom: 40px;
    position: relative;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;

    ${media.phone`
		padding-top: 20px;
		padding-bottom: 20px;
	`}

    ${Wrapper} {
        display: flex;

        ${media.phone`
			flex-direction: column;
		`}
    }
`

// Roster

const Roster = styled.div`
    flex: 0 1 calc(33.33% - 40px);
    display: flex;
    flex-direction: column;

    ${media.tablet`
		flex: 0 1 50%;
		padding-bottom: 20px;
	`}

	${media.phone`
		flex: 0 1 100%;
	`}

    ${Heading} {
        color: ${colours.red};
        font-size: 24px;
        padding-bottom: 18px;
        margin: 0;
    }

    ${Item} {
        color: ${colours.black};
        text-transform: uppercase;
        padding-bottom: 8px;
        display: block;

        ${Wrapper} {
            position: relative;
            margin: 0;

            ${Icon} {
                position: absolute;
                width: 32px;
            }

            ${Character} {
                position: absolute;
                top: -8px;
                left: -20px;
                max-width: 70px;
            }
        }

        ${Label} {
            padding-left: 62px;
            margin: 0;

			${media.tablet`
				font-size: 24px;
				line-height: 1.3;
			`}

            &:hover {
                color: ${colours.red};
            }
        }
    }
`

// Info

const Info = styled.div`
    flex: 0 1 calc(33.33% - 40px);

    ${media.tablet`
		flex: 0 1 50%;
	`}

    ${media.phone`
		flex: 0 1 100%;
	`}
`

const Contact = styled.div`
    ${Heading} {
        color: ${colours.red};
        font-size: 24px;
        padding-bottom: 18px;
        margin: 0;
    }

    ${Text} {
        p,
        a {
            color: ${colours.black};
            font-size: 24px;
        }

        a {
            &:hover {
                color: ${colours.red};
            }
        }
    }
`

// Follow

const Follow = styled.div`

	${Heading} {
        color: ${colours.red};
        font-size: 24px;
        padding-bottom: 18px;
        margin: 0;
    }

	 ${Icon} {
        width: 30px;
        height: auto;
        cursor: pointer;

        /*${media.phone`
			width: 20px;
		`}*/

		&:hover {
			opacity: 0.8;
		}
    }
`

// Newsletter

const formStyles = css`
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    width: 100%;

    .dynamic-fields {
        width: 100%;
        display: flex;
        border-bottom: 1px solid black;

        ${media.tablet`
			flex-direction: column;
			align-items: center;
			padding-right: 0;
		`}
    }

    .dynamic-field {
        margin-bottom: 16px;
        width: 100%;
        height: 42px;

        input {
            width: 100%;
            background: transparent;
            padding: 0;
            letter-spacing: 0;
            font-size: 24px;

            &::placeholder {
                letter-spacing: 0;
                font-size: 24px;
            }
        }

        &.email {
            margin-right: 5px;
        }
    }

    .error {
        margin-top: 32px;

        &,
        p,
        a {
            font-size: 16px;
            color: ${colours.black} !important;
        }
    }
`

// Meta

const Meta = styled.div`
    display: flex;
    align-items: flex-end;

    ${media.tablet`
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 380px;
	`}

	${media.phone`
		flex-direction: column;
		align-items: flex-start;
		margin-top: 20px;
		margin-bottom: 300px;
	`}


    ${Text} {
        flex: 0 1 calc(33.33% - 40px);
        font-size: 18px;
        color: ${colours.black};
		z-index: 400;
		display: block;
    }

    ${Copyright} {
        flex: 0 1 calc(33.33% - 40px);
        font-size: 18px;
        color: #cccccc;
        margin: 0;
    }

    ${Arrow} {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 60px;
        box-sizing: border-box;
		z-index: 400;
		cursor: pointer;

        ${media.tablet`
			left: 0;
			right: auto;
			padding: 20px 20px 30px;
		`}
    }
`

const theCharacters = [char01, char02, char03, char04]

const animatedContainer = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5,
            delayChildren: 1,
            yoyo: Infinity,
        },
    },
}

const animatedItem = {
    hidden: {
        scaleX: 0,
        scaleY: 0,
    },
    show: {
        scaleX: 1,
        scaleY: 1,
        transition: {
            ease: 'anticipate',
        },
    },
}

export default Footer
