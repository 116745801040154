import { BlockGridItem, BlockAboutList } from './templates'

export const resolveBlock = (layout) => {
    let block

    // Map layout strings to blocks

    const blocks = {
        'grid-item': BlockGridItem,
        'about-list': BlockAboutList,
    }

    return blocks[layout]
}
