import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition'

import { MetaTags, Header, Logo, Footer } from '../'
import { media } from '../../styles/utils'

const Layout = ({
    children,
    meta,
    backgroundColor,
    theme,
    useBlackCharacters,
    hideFooter,
    menuMessage,
    stopResize,
    ...props
}) => {
    return (
        <>
            <Background backgroundColor={backgroundColor}>
                <MetaTags {...meta} />

                <Logo
                    theme={theme}
                    useBlack={useBlackCharacters}
                    stopResize={stopResize}
                />

                <Header
                    hideLogo={props.hideLogo}
                    theme={theme}
                    menuMessage={menuMessage}
                />

                <Wrapper>
                    <Transition>{children}</Transition>
                </Wrapper>

                {!hideFooter && <Footer />}
            </Background>
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;

    ${media.phone`
		font-size: 52px;
		margin-right: 100px;
		height: 100%;
		min-height: fill-available;
	`}
`

const Background = styled.div`
    transition: background 1s ease;
    background: transparent;

    ${(props) => {
        if (props.backgroundColor)
            return css`
                background: ${(props) => props.backgroundColor};
            `
    }}
`

export default Layout
