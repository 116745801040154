import { forEach } from 'lodash'
import { useStaticQuery, Link } from 'gatsby'

export const getVideoURL = (iframe) => {
    if (iframe) {
        return iframe?.match(/src="(.+?)"/)?.[1]
    }
}

export const parseACF = (queryData, nodeName, debug) => {
    if (queryData && queryData[nodeName]) {
        const nodes = queryData[nodeName].nodes
        const acfJSON = nodes && nodes[0].acf_json

        if (!acfJSON) return

        // Console log out if debug = true

        debug && console.log(JSON.parse(acfJSON))

        // Return parsed ACF JSON

        return JSON.parse(acfJSON)
    }
}

// Group option page keys by prefix

export const groupByPrefix = (data, prefix) => {
    let items = {}

    forEach(data, (item, key) => {
        if (key.includes(prefix)) {
            items[key.replace(`${prefix}_`, '')] = item
        }
    })

    return items
}

// Get global data

export const getGlobalData = (prefix) => {
    const query = graphql`
        query {
            allWordpressGlobal {
                nodes {
                    acf_json
                }
            }
        }
    `

    // Fetch global data

    const globalData = parseACF(useStaticQuery(query), 'allWordpressGlobal')

    // Return global + grouped by prefix

    return {
        global: globalData,
        data: groupByPrefix(globalData, prefix),
    }
}

// Scroll to ref

export const scrollToRef = (ref) => {
    if (!ref || !ref.current) return

    try {
        window.scroll({
            top: ref.current.offsetTop,
            left: 0,
            behavior: 'smooth',
        })
    } catch (error) {
        window.scrollTo(ref.current.offsetTop, 0)
    }
}

export const scrollToTop = () => {
    try {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    } catch (error) {
        window.scrollTo(0, 0)
    }
}

// Randomly select from Array

export const getRandom = (array) => {
    var index = Math.floor(Math.random() * array.length)

    return array[index]
}

// Image Crop

export const getImageCrop = (image) => {
    if (!image || !image.focal_points) return

    if (image.focal_points.enabled != 1) return
    return {
        backgroundPositionX: `${image.focal_points.pos.left}%`,
        backgroundPositionY: `${image.focal_points.pos.top}%`,
    }
}

// Get Link props

export const hasLink = (use_links, url) => {
    if (!use_links) return
    return {
        as: Link,
        to: url,
    }
}

// Validators

export const required = (value) => value && value !== '' && value.length >= 1

export const requiredBool = (value) => {
    return value
}

export const email = (value) => {
    const emailTest = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailTest.test(value)
}

export const phone = (value) => {
    if (!value) return false
    const phoneTest = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/
    return phoneTest.test(value)
}

export const number = (value) => {
    const numberTest = /^[0-9]+$/
    return numberTest.test(value)
}

// min 8 letter password, with at least a symbol, upper and lower case letters and a number
export const password = (value) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    return re.test(value)
}

export const date = (dates) => {
    const { startDate, endDate } = dates || {}
    if (startDate) return true
}
