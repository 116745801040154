import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'

import globalType from './type'
import globalColours from './colours'
import globalZindex from './zindex'

const maxWidth = 656

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        width: 100%;
        background: ${globalColours.bone};
        color: ${globalColours.black};
		${globalType.body};
    }

	::selection {
		background: ${globalColours.red};
		color: ${globalColours.black};
	}

	/* width */
	::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #fff;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #f91c0e;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #000;
	}

    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        ${globalType.body};
        letter-spacing: 0.15px;
        font-weight: 300;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p:first-child {
		margin-top: 0;
	}

    /* Next Wrapper */

    #__next {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    /* Gatsby Wrapper */

    #gatsby-focus-wrapper, #___gatsby {
        width: 100%;
        height: 100%;
	}
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`

export const container = css`
    display: flex;
    width: 100%;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;

    ${media.tablet`
		padding-left: 20px;
        padding-right: 20px;
	`}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType
export const zindex = globalZindex
