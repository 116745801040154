import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'

import { media } from '../../../styles/utils'
import { type, colours } from '../../../styles/global'

const BlockGridItem = (props) => {
    const { acf, slug, title } = props
    const [play, playVideo] = useState(false)
    const [videoLoaded, setVideoLoaded] = useState(false)

    // Video

    const renderVideo = () => {
        if (!acf?.list_video) return

        return (
            <VideoWrapper
                whileHover={{ borderRadius: 30 }}
                onMouseEnter={() => {
                    playVideo(true)
                }}
                onMouseLeave={() => {
                    playVideo(false)
                }}
                onTouchStart={() => {
                    playVideo(true)
                }}
                onTouchEnd={() => {
                    playVideo(false)
                }}
            >
                <Video
                    loaded={videoLoaded}
                    playing={play}
                    muted
                    loop
                    playsinline
                    url={acf?.list_video_vimeo ? acf?.list_video_vimeo : acf?.list_video}
                    width={'100%'}
                    height={'100%'}
                    //onReady={() => setVideoLoaded(true)}
                    //style={{
                    //    opacity: videoLoaded ? 1 : 0,
                    //}}
                />
            </VideoWrapper>
        )
    }

    return (
        <Container as={Link} to={`/project/${slug}`} className={'grid-item'}>
            {renderVideo()}
            <Meta>
                {acf?.brand && (
                    <Brand className={'content'} dangerouslySetInnerHTML={{ __html: acf?.brand }} />
                )}

                {title && <Title className={'content'} dangerouslySetInnerHTML={{ __html: title }} />}

                {acf?.type && (
                    <Type className={'content'} dangerouslySetInnerHTML={{ __html: acf?.type }} />
                )}
            </Meta>
        </Container>
    )
}

// Shared

const Video = styled(ReactPlayer)``
const Brand = styled.h2``
const Title = styled.h2``
const Type = styled.h2``

// Block Wrapper

BlockGridItem.wrapper = css`
    margin-bottom: 0;
    width: calc(50% - 6px);
    white-space: nowrap;

    ${media.tablet`
		width: 100%;
	`}
`

// Container

const Container = styled.a`
	color: ${colours.black};

    ${Brand}, 
	${Title}, 
	${Type} {
		font-size: 20px;
        text-align: left;

        ${media.tablet`
            text-align: center;
        `}
	}

	&:hover {
		${Title} {
			color: ${colours.red};
		}
	}
`

// Video

const VideoWrapper = styled(motion.div)`
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: ${colours.white};

    ${Video} {
        display: flex;
        object-fit: cover;
        transition: 1s ease;
        padding-bottom: 56.25%;

        video {
            position: absolute;
            object-fit: cover;

            // ${media.tablet`
			// 	min-height: 280px;
			// `}
        }
    }
`

// Caption

const Meta = styled.div`
    text-align: center;
    padding-top: 20px;
    box-sizing: border-box;
`

export default BlockGridItem
