import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils'

const Block = (props) => {
    const { layout, lastBlock, block_type, inAccordion, display, theme } = props
    const BlockLayout = resolveBlock(layout)

    if (!BlockLayout || !display) return <></>

    return (
        <Wrapper
            className={`block_${layout}`}
            layout={layout}
            theme={theme}
            blockType={block_type}
            blockProps={props}
            styles={BlockLayout && BlockLayout.wrapper}
            lastBlock={lastBlock}
            inAccordion={inAccordion}
        >
            {BlockLayout && <BlockLayout {...props} />}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 60px;

    /* Block specific container styles */

    ${(props) => {
        if (props.styles) return props.styles
    }}
`

export default Block
