import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`
        font-size: 82px;

        ${media.phone`
			font-size: 48px;
		`}
    `,
    heading2: css`
        font-size: 40px;

        ${media.phone`
			font-size: 30px;
		`}
    `,
    heading3: css``,
    heading4: css``,
    body: css`
        font-size: 32px;
        line-height: 1.2;
        font-weight: 500;
        font-family: 'Graebenbach-Regular';

        ${media.phone`
          font-size: 24px;
        `}
    `,
    bodySmall: css`
        font-size: 18px;
        line-height: 1.2;
        font-weight: 500;
        font-family: 'Graebenbach-Regular';

        ${media.phone`
          font-size: 14px;
        `}
    `,
}

export default type
